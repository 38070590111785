import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import { Block } from "glamor/jsxstyle";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

/**
 * Manual page
 *
 * This is is a playbook for me and how I work. It captures what you can expect out
 * of me, how I like to work, my North Star principles, and some additional nuance.
 * Hopefully this can accelerate our working relationship.
 */
const Manual = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout title={siteTitle}>
      <h1 style={{ fontSize: "2.5rem", marginBottom: "1.5rem" }}>
        User Manual
      </h1>
      <SEO
        title={"Manual | Conor Dewey"}
        description={`This is a playbook on everything Conor. It captures what you can
          expect out of me, how I like to work, and some core principles. `}
        pathname={"manual"}
      />
      <section
        style={{
          fontSize: "1.1rem",
          lineHeight: "32px"
        }}
      >
        <Block
          fontSize="1.3rem"
          lineHeight="2rem"
          className="pageTemplateSubheader"
          fontWeight="500"
          paddingBottom="2.5rem"
        >
          This is a playbook on everything Conor. It captures more about me and
          how I operate in a working environment.
        </Block>
        <Block>
          <div className="manualHeader">
            <span role="img">🏠</span> Overview
          </div>
        </Block>
        <Block paddingBottom={"1.4rem"}>
          <ul>
            <li>
              <strong>INTJ-A Myers-Briggs: </strong>
              Introverted, Intuitive, Thinking, Judging, and Assertive. I think
              this{" "}
              <a
                href="https://www.16personalities.com/intj-personality"
                target="_blank"
                rel="noopener noreferrer"
              >
                description
              </a>{" "}
              is a solid one.{" "}
            </li>
            <li>
              <strong>5-3-1 Enneagram: </strong>
              Investigator, Achiever, and Perfectionist. "Remarkably efficient,
              hard-working and competent, although a little bit anal. While
              sometimes self-righteous and impatient with others, they are
              pragmatic and tactful enough not to compromise their goals by
              being too inflexible. Can be clever, self-confident,
              perfectionist, arrogant and glacial."{" "}
              <a
                href="https://www.personalitycafe.com/enneagram-personality-theory-forum/73452-27-tritype-archetype-descriptions.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Yikes.
              </a>
            </li>
          </ul>
        </Block>
        <Block>
          <div className="manualHeader">
            <span role="img">👍🏼</span> Strengths
          </div>
        </Block>
        <Block paddingBottom={"1.4rem"}>
          <ul>
            <li>
              <strong>Cross-functional: </strong> I’m a generalist at heart and
              enjoy projects that utilize a broad set of skills across product,
              data, and growth.
            </li>
            <li>
              <strong>Systems thinking: </strong> I reduce complexity into
              systems in order to better understand. I love thinking deeply
              about interesting problems.
            </li>
            <li>
              <strong>Moving fast: </strong> I get frustrated when I’m not
              producing frequent output and therefore I like to iterate at a
              pretty quick pace.
            </li>
          </ul>
        </Block>

        <Block>
          <div className="manualHeader">
            <span role="img">👎🏼</span> Weaknesses
          </div>
        </Block>
        <Block paddingBottom={"1.4rem"}>
          <ul>
            <li>
              <strong>Operating without context: </strong> I struggle
              contributing to decisions where I don’t have sufficient context or
              background.
            </li>
            <li>
              <strong> Starting simple: </strong> My brain will skip baseline
              approaches and try to do too much, too fast. Don't be afraid to
              rein me in.
            </li>
            <li>
              <strong>Overextending myself: </strong> I get excited by lots of
              things and sometimes (okay, all the time) that means I spread
              myself too thin.
            </li>
          </ul>
        </Block>
        <Block>
          <div className="manualHeader">
            <span role="img">🛠</span> Principles
          </div>
        </Block>
        <Block paddingBottom={"1.4rem"}>
          <ul>
            <li>
              <strong>Bias towards action: </strong> Getting started is the best
              way to begin making progress and start learning. Avoid unchecked
              inaction.
            </li>
            <li>
              <strong>Growth mindset: </strong> I’m constantly challenging
              myself to improve. When I get too comfortable, my work and
              happiness suffer.
            </li>
            <li>
              <strong>Bigger bets: </strong> We should be willing to pass on
              small opportunities in order to focus on potential step changes.
            </li>
            <li>
              <strong>Time for thought: </strong> I block off time to explore
              ideas that might not relate to current projects. Without this
              time, I feel like a machine.
            </li>
            <li>
              <strong>Keep it simple: </strong> Sometimes we have to go into the
              weeds, but it shouldn't be the default. If it's not the focus,
              keep it simple.
            </li>
            <li>
              <strong>Opinions are useful: </strong> Both people and products
              should be opinionated. Taking a stance helps move the conversation
              forward.
            </li>
            <li>
              <strong>Learning in public: </strong> I'm happier when I'm sharing
              things that I find helpful. This often means a steady drip of blog
              posts.
            </li>
          </ul>
        </Block>
        <Block>
          <div className="manualHeader2">
            <span role="img">📚</span> Favorite Books
          </div>
        </Block>
        <Block paddingBottom={"1.4rem"}>
          What I know and how I think is heavily influenced by the things I've
          read. Here are a few of the big ones:
          <ul>
            <li>
              <a
                href="https://www.amazon.com//dp/B005GFBNSW/"
                target="_blank"
                rel="noopener noreferrer"
              >
                The Way to Love: Meditations for Life
              </a>
            </li>
            <li>
              <a
                href="https://www.amazon.com/dp/B071KJ7PTB/ref=dp-kindle-redirect?_encoding=UTF8&btkr=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                Tribe of Mentors: Short Life Advice from the Best in the World
              </a>
            </li>
            <li>
              <a
                href="https://www.amazon.com/Poor-Charlies-Almanack-Charles-Expanded/dp/1578645018"
                target="_blank"
                rel="noopener noreferrer"
              >
                Poor Charlie's Almanack: The Wit and Wisdom of Charlie Munger
              </a>
            </li>

            <li>
              <a
                href="https://www.amazon.com/Mental-Game-Baseball-Guide-Performance-ebook/dp/B009R6GJDA/"
                target="_blank"
                rel="noopener noreferrer"
              >
                The Mental Game of Baseball: A Guide to Peak Performance
              </a>
            </li>
          </ul>
        </Block>
        <Block>
          <div className="manualHeader">
            <span role="img">🙏🏼</span> Favorite Quote
          </div>
        </Block>
        <Block paddingBottom={"2.4rem"}>
          <blockquote>
            Put a ding in the universe. Your time is limited, so don’t waste it
            living someone else’s life. Don’t be trapped by dogma, which is
            living with the results of other people’s thinking. —{" "}
            <a
              href="https://www.youtube.com/watch?v=UF8uR6Z6KLc"
              target="_blank"
              rel="noopener noreferrer"
            >
              Steve Jobs
            </a>
          </blockquote>
        </Block>
        <Block>
          <div className="manualHeader2">
            <span role="img">📲</span> Get in touch
          </div>
        </Block>
        <Block>
          If you want to get in touch, I'm most responsive over{" "}
          <a href="mailto:conordewey3@gmail.com">email</a> and I tend to be
          pretty active on{" "}
          <a
            href="https://twitter.com/cdeweyx"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>{" "}
          as well. Let me know what's on your mind!
        </Block>
      </section>
    </Layout>
  );
};

export default Manual;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
